<template>
  <div id="cctv-list-view">
    <div class="popup" :class="{ show: isShowMask }">
      <button class="btn-close-window" @click="isShowMask = false"></button>
      <video
        v-if="isShowMask"
        controls="controls"
        class="demo-video"
        id="player-chosen"
        muted
      ></video>
    </div>
    <div class="mask" :class="{ show: isShowMask }"></div>
    <div class="spot-key">
      <span>{{ $route.params.key }}</span>
    </div>
    <div class="content-box">
      <div class="map-list">
        <button class="btn-cctv" @click="goCctvList">
          <img src="../assets/camera.png" /><span>CCTV 整合</span>
        </button>

        <img
          @click="goStation(stationInfo.key)"
          class="preview-dot"
          :src="require('../assets/' + stationInfo.key + '-dot.png')"
          v-for="stationInfo in stationInfoList"
          :key="stationInfo.key"
          :class="{ active: stationInfo.key === $route.params.key }"
        />
      </div>
      <div class="video-block">
        <div class="video-box" v-for="(ws, index) in cctvData">
          <video
            controls="controls"
            class="demo-video"
            :id="'player' + index"
            muted
            @dblclick="fullScreen"
          ></video>
          <button class="zoom-tv" @click="choseTv(ws)">放大</button>
        </div>
      </div>
      <div class="page-row">
        <button class="page-prev" @click="turnPage(1)">
          <img src="../assets/back.png" />
        </button>
        <button
          class="page-index"
          v-for="index in Math.ceil(cctvData.length / 6)"
          :class="{ active: nowPage === index }"
          @click="turnPage(index)"
        >
          <span> {{ index }}</span>
        </button>
        <button
          class="page-next"
          @click="turnPage(Math.ceil(cctvData.length / 6))"
        >
          <img src="../assets/back.png" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCctv } from '@/api/index.js'
import flvjs from 'flv.js'
export default {
  mixins: [],
  data() {
    return {
      player: null,
      loading: true,
      cctvData: [],
      nowPage: 1,
      choseTvInfo: null,
      isShowMask: false,
      windowWidth: window.innerWidth,
    }
  },
  created() {},
  mounted() {
    // this.$createElement
    getCctv({ group: this.spotKey, page: 1, pageSize: 6 })
      .then((res) => {
        this.cctvData = res.data.rows
        this.$nextTick(() => {
          this.playVideo()
        })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  computed: {
    ...mapState(['isLogin', 'stationInfoList']),

    spotKey() {
      if (this.$route.params.key !== 'nt') {
        return this.$route.params.key.toUpperCase()
      } else {
        return 'NORTH'
      }
    },
  },
  methods: {
    choseTv(ws) {
      if (this.windowWidth < 768) {
        this.isShowMask = true
        console.log('ws', ws)
        const time1 = new Date().getTime()
        if (flvjs.isSupported()) {
          setTimeout(() => {
            let video = document.getElementById('player-chosen')
            if (video) {
              this.player = flvjs.createPlayer(
                {
                  type: 'flv',
                  isLive: true,
                  url:
                    'wss://tycg-backend.skysharp.com.tw/ad-machine?groupId=' +
                    this.spotKey +
                    '&cameraId=' +
                    ws.cameraId,
                  hasAudio: false,
                },
                {
                  autoCleanupSourceBuffer: true,
                  enableWorker: false, //不启用分离线程
                  enableStashBuffer: true, //关闭IO隐藏缓冲区
                  isLive: true,
                  lazyLoad: false,
                }
              )
              this.player.attachMediaElement(video)
              try {
                this.player.load()
                this.player.play().then(() => {
                  console.log(new Date().getTime() - time1)
                  this.loading = false
                })
                this.listenVideo()
              } catch (error) {
                console.log(error)
              }
            }
          }, 1000)
        }
      } else {
        this.$router.push({
          name: 'Cctv',
          params: {
            key: this.spotKey.toLowerCase(),
            id: ws.cameraId,
          },
        })
      }
    },
    turnPage(page) {
      getCctv({ group: this.spotKey, page: page, pageSize: 6 })
        .then((res) => {
          this.cctvData = res.data.rows
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fullScreen() {
      if (this.$refs.player.requestFullScreen) {
        this.$refs.player.requestFullScreen()
      } else if (this.$refs.player.mozRequestFullScreen) {
        this.$refs.player.mozRequestFullScreen()
      } else if (this.$refs.player.webkitRequestFullScreen) {
        this.$refs.player.webkitRequestFullScreen()
      }
    },
    playVideo() {
      console.log('playVideo', flvjs.isSupported(), this.cctvData.length)
      const time1 = new Date().getTime()
      if (flvjs.isSupported()) {
        for (let i = 0; i < this.cctvData.length; i++) {
          setTimeout(() => {
            let video = document.getElementById('player' + i)
            if (video) {
              console.log('set', i, this.player)
              this.player = flvjs.createPlayer(
                {
                  type: 'flv',
                  isLive: true,
                  url:
                    'wss://tycg-backend.skysharp.com.tw/ad-machine?groupId=' +
                    this.spotKey +
                    '&cameraId=' +
                    this.cctvData[i].cameraId,
                  hasAudio: false,
                },
                {
                  autoCleanupSourceBuffer: true,
                  enableWorker: false, //不启用分离线程
                  enableStashBuffer: true, //关闭IO隐藏缓冲区
                  isLive: true,
                  lazyLoad: false,
                }
              )
              this.player.attachMediaElement(video)
              try {
                this.player.load()
                this.player.play().then(() => {
                  console.log(new Date().getTime() - time1)
                  this.loading = false
                })
                this.listenVideo()
              } catch (error) {
                console.log(error)
              }
            }
          }, i * 1000)
        }
      }
    },
    listenVideo() {
      console.log('listenVideo', this.player)
      this.player.on(
        flvjs.Events.ERROR,
        (errorType, errorDetail, errorInfo) => {
          console.log('Events.ERROR', this.player)
          console.log('errorType', errorType)
          console.log('errorDetail', errorDetail)
          console.log('errorInfo', errorInfo)
          // 视频出错后销毁重建
          if (flvjs.isSupported()) {
            this.destroyFlv()
            this.playVideo()
          }
        }
      )
      // 视频断流
      // this.player.on('statistics_info', function (res) {
      //   if (this.lastDecodedFrames === 0) {
      //     this.lastDecodedFrames = res.decodedFrames
      //     return
      //   }
      //   if (this.lastDecodedFrames != res.decodedFrames) {
      //     this.lastDecodedFrames = res.decodedFrames
      //   } else {
      //     this.lastDecodedFrames = 0
      //     if (flvjs.isSupported()) {
      //       this.destroyFlv()
      //       this.playVideo()
      //     }
      //   }
      // })
    },

    destroyFlv() {
      if (this.player) {
        this.player.pause()
        this.player.unload()
        this.player.detachMediaElement()
        this.player.destroy()
        this.player = null
      }
    },
    goCctvList() {
      this.player = null
      this.$router.push({
        name: 'CctvList',
        params: { key: this.$route.params.key },
      })
    },
    goStation(key) {
      if (!this.isLogin) return
      console.log(' goStation key', key)
      this.player = null
      // this.websocketclose()
      this.$router.replace({ name: 'Map', params: { key: key } })
    },
  },
  beforeDestroy() {
    console.log('cctv list beforeDestroy')
  },
  destroy() {
    // this.websocketclose() // 關閉websocket通道
  },
}
</script>

<style></style>
